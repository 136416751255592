import React from 'react';

import zeropad from '../helpers/zeropad';

const Timer = ({ minutes, seconds }) => {
    return (
        <div className="count-down">
            <span className="minutes">{minutes}</span>
            :
            <span className="seconds">{zeropad(seconds, 2)}</span>
        </div>
    );
}

export default Timer;