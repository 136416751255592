import React from 'react';

import EndScreen from './EndScreen';
import Timer from './Timer';

const CountDown = ({
    completed,
    handleClick,
    minutes,
    seconds,
}) => {
    // Start timer at 60:00 instead of 00:00
    if(minutes === 0 && seconds === 0){
        minutes = 60;
    }

    const Component = completed
        ? <EndScreen handleClick={handleClick} />
        : <Timer minutes={minutes} seconds={seconds} />;

    return Component;
};

export default CountDown;