import React from 'react';

const Sound = ({ handleRef }) => {
    return (
        <audio ref={handleRef} className="drink-sound">
            <source src="./drink.mp3" type="audio/mpeg" />
            <source src="./drink.ogg" type="audio/ogg" />
        </audio>
    );
}

export default Sound;