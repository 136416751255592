import classNames from 'classnames';
import React, { Component } from 'react';
import Countdown from 'react-countdown';

import CountDown from './components/CountDown';
import Sound from './components/Sound';
import StartScreen from './components/StartScreen';

class PowerHour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasStarted: false,
      isDrinking: false,
      startTime: null,
      timeInterval: 3600000
    }

    // Default to an hour
    this.countdownRef = React.createRef();
    this.soundRef = React.createRef();
  }

  start() {
    const { timeInterval } = this.state;

    this.setState({
      hasStarted: true,
      startTime: Date.now() + timeInterval
    });

    this.soundRef.current.play();
  }

  restart() {
    const api = this.countdownRef.current.getApi();
    this.start();
    api.start();
  }

  triggerDrink(delta) {
    const { seconds } = delta;

    if (seconds === 0) {
      this.setState({ isDrinking: true });
      this.soundRef.current.play()
    }
  }

  componentDidMount() {
    this.soundRef.current.addEventListener('ended', event => {
      this.setState({ isDrinking: false });
    });
  }

  render() {
    const { hasStarted, isDrinking, startTime } = this.state;
    const className = classNames('power-hour', { 'drinking': isDrinking })

    return (
      <div className={className}>
        <div className="content">
          {isDrinking &&
            <h2 className="drink-warn">Drink!</h2>
          }
          {hasStarted &&
            <Countdown
              date={startTime}
              renderer={(props) => <CountDown {...props} handleClick={() => this.restart()} />}
              ref={this.countdownRef}
              onTick={(delta) => this.triggerDrink(delta)}
            />
          }
          {!hasStarted && <StartScreen handleClick={() => this.start()} />}
          <Sound handleRef={this.soundRef} />
        </div>
      </div>
    );
  }
}

export default PowerHour;
