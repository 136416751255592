import React from 'react';

const StartScreen = ({ handleClick }) => {
    return (
        <div className="start-screen text-center">
            <h1 className="mb-5">Get ready for power hour <span role="img" aria-label="beer">🍺</span>!</h1>
            <button className="start-btn btn btn-lg btn-primary" onClick={handleClick}>Start</button>
        </div>
    );
};

export default StartScreen;