import React from 'react';

const EndScreen = ({ handleClick }) => {
    return (
        <div className="end-screen">
            <h2>Are you drunk yet?</h2>
            <button
                className="mt-5 start-btn btn btn-lg btn-primary"
                onClick={handleClick}>Play Again
            </button>
        </div>
    );
}

export default EndScreen;